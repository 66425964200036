

















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { publicPageGuard } from '@/app/shared/config/auth-guards';
import rules from '@/app/shared/validation-rules';
import { verifyCodeAndResetPasswordAction } from '@publicApp/shared/actions';
import routesNames from '@/app/shared/utilities/routes-names';
import FormComponent from '@/app/shared/components/FormComponent.vue';
import AppPublicLayout from '@publicApp/shared/components/AppPublicLayout.vue';

@Component({
  beforeRouteEnter: publicPageGuard,
  components: {
    FormComponent,
    AppPublicLayout,
  },
  data: (vm: any) => ({
    emailRules: [
      rules.required(vm.$i18n.t('field_required')),
      rules.emailFormat(vm.$i18n.t('invalid_email')),
    ],
    passwordRules: [rules.required(vm.$i18n.t('field_required'))],
    confirmPasswordRules: [rules.required(vm.$i18n.t('field_required'))],
  }),
})
export default class ForgetPassword extends Vue {
  public get code() {
    return this.$route.query.oobCode as string;
  }

  public showPassword = false;
  public showConfirmPassword = false;
  public newPassword = '';
  public confirmNewPassword = '';

  public async resetPassword() {
    try {
      await verifyCodeAndResetPasswordAction(this.code, this.newPassword);
      this.$router.push(routesNames.DASHBOARD);
    } catch (error) {
      throw new Error(`Error resitting password => ${error.message}`);
    }
  }

  public passwordsMatch() {
    return (
      this.newPassword === this.confirmNewPassword || this.$t('passwords_dont_match')
    );
  }

  public created() {
    if (!this.code) {
      this.$router.replace(routesNames.LOGIN);
    }
  }
}
